import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import {
  SectionHeader,
  SectionParagraph,
  SectionContact,
} from "../components/SectionArticle"

const ContactPage = () => {
  return (
    <Layout titlePage="Contact" classNameNav="bg-green-700 text-white">
      <Seo
        title="Syawal Jasira Contact Page of Personal and Portfolio Website"
        description="Syawal jasira contact information and social media"
        siteUrl="https://www.syawaljasira.com/contact"
      />
      <div className="py-20 md:py-28 xl:py-32">
        <header>
          <SectionHeader>Contact</SectionHeader>
          <span className="text-center">
            <SectionParagraph>
              For more info you can contact me.
            </SectionParagraph>
          </span>
        </header>
        <main>
          <article className="flex flex-wrap md:pt-8 xl:pt-12 w-11/12 sm:w-10/12 md:w-8/12 mx-auto justify-around">
            <SectionContact
              tech="gmail"
              title="Email"
              desc="syawaljasira@gmail.com"
              url="https://mailto:syawaljasira@gmail.com"
            />
            <SectionContact
              tech="linkedin"
              title="LinkedIn"
              desc="syawaljasira"
              url="https://www.linkedin.com/in/syawal-jasira-b3154a170/"
            />
            <SectionContact
              tech="github"
              title="Github"
              desc="syawaljasira"
              url="https://www.github.com/syawaljasira"
            />
          </article>
        </main>
      </div>
    </Layout>
  )
}

export default ContactPage
